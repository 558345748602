* {
    transition: all 0.1s ease;
    --fc-highlight-color: #a28ff5b0;
}

/* width */
::-webkit-scrollbar {
    display: none;
}

.fc button,
.fc table,
body .fc {
    font-size: 1em;
    border-width: 0.5px;
    width: 90%;
}

.fc .fc-view-harness {
    flex-grow: 1;
    position: relative;
    height: 600px !important;
}

.fc .fc-toolbar-chunk {
    display: flex;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 90%;
    width: 100%;
    border-color: #3f3f46;
    background-color: #3f3f46;
    border-radius: 10px;
    border-width: 2px;
    overflow: scroll;
}

.fc .fc-scrollgrid-liquid {
    height: 90%;
    width: fit-content;
    border-color: #303030;
    border-radius: 5px;
    border-width: 2px;
}

.fc .fc-scrollgrid-section>* {
    border-width: 0;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
    box-shadow: 0 8px 32px 0 rgba(6, 6, 6, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    padding: 10px;
    font-size: 0.7rem;
    margin: 5px;
    border: 1px solid rgba(255, 255, 255, 0.18);

}

.fc-timegrid-event .fc-event-main {
    padding: 1px 5px 0;
    overflow-y: scroll;
}

.fc-daygrid-day-events {
    padding: 10px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 80%;
    width: 100%;
    border-color: #3c3c3c;
    background-color: #3c3c3c;
    border-width: 2px;
    overflow: scroll;
    padding: 10px;
    margin: 5px;
    font-size: 0.7rem;
    border-radius: 2px;
}

.fc-timegrid-event .fc-event-main::-webkit-scrollbar,
.fc-direction-ltr .fc-daygrid-event.fc-event-end::-webkit-scrollbar,
.fc-direction-rtl .fc-daygrid-event.fc-event-start::-webkit-scrollbar {
    display: block;
    width: 3px;
    height: 3px;
}



.fc-timegrid-event .fc-event-main::-webkit-scrollbar-track,
.fc-direction-ltr .fc-daygrid-event.fc-event-end::-webkit-scrollbar-track,
.fc-direction-rtl .fc-daygrid-event.fc-event-start::-webkit-scrollbar {
    background: #fff;
}

.fc-timegrid-event .fc-event-main::-webkit-scrollbar-thumb,
.fc-direction-ltr .fc-daygrid-event.fc-event-end::-webkit-scrollbar-thumb,
.fc-direction-rtl .fc-daygrid-event.fc-event-start::-webkit-scrollbar-thumb {
    background: #aaa;
}

.fc-timegrid-event .fc-event-main::-webkit-scrollbar-thumb:hover,
.fc-direction-ltr .fc-daygrid-event.fc-event-end::-webkit-scrollbar-thumb:hover,
.fc-direction-rtl .fc-daygrid-event.fc-event-start::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.fc-day-today.fc-timegrid-col div:hover,
.fc-day-past.fc-timegrid-col div:hover,
.fc-day-future.fc-timegrid-col div:hover {
    border-radius: 10px;
    z-index: 99 !important;
}

.fc-day-today.fc-timegrid-col>div>div a:hover,
.fc-day-past.fc-timegrid-col>div>div a:hover,
.fc-day-future.fc-timegrid-col>div>div a:hover {
    filter: drop-shadow(0 0 0.75rem rgb(209, 209, 209));
}

.fc-day-today.fc-timegrid-col>div>div,
.fc-day-past.fc-timegrid-col>div>div,
.fc-day-future.fc-timegrid-col>div>div {
    width: 12vw !important;
}


#root>div.sc-jeCNp.hwHzJw>div>div.fc-view-harness.fc-view-harness-active>div>table>tbody>tr>td>div>div>div>div.fc-timegrid-cols>table>tbody>tr>td.fc-day.fc-day-tue.fc-day-today.fc-timegrid-col>div>div.fc-timegrid-now-indicator-container>div {
    display: none;
}

.fc-timegrid-col{
    overflow-x: scroll;
}

.fc-timegrid-now-indicator,
.fc .fc-timegrid-now-indicator-line {
    display: none !important;
  }
  
.fc-direction-ltr .fc-timegrid-now-indicator-arrow {
    border-bottom-color: transparent;
    border-top-color: transparent;
    border-width: 8px 0 8px 8px;
    left: 0;
}
.fc .fc-timegrid-now-indicator-arrow {
    border-color: #636363;
    border-bottom-color: transparent;
    border-top-color: transparent;
}

.fc-direction-ltr .fc-button-group>.fc-button {
    margin-left: -1px;
    background: #2d2554;
    border: none;
    display: flex;
}

.fc .fc-button-primary:disabled {
    background-color: var(--fc-button-bg-color);
    color: var(--fc-button-text-color);
    border: none;
    display: flex;
}

.fc .fc-timegrid-slot-minor {
    border-top-style: dotted;
    border-color: #b0b0b060;
}


.fc-theme-standard td, .fc-theme-standard th {
    border: 1px solid #828282;
}
  

.fc-theme-standard td, .fc-theme-standard th {
    border: 1px solid #828282;
}