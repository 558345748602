:root {
    --fc-list-event-hover-bg-color: #5bec5352;
}

.fc .fc-view-harness {
    flex-grow: 1;
    position: relative;
    
}

.fc-theme-standard .fc-list {
    border: none !important;
}
.fc-theme-standard .fc-list-day-cushion{
    background-color: #181818 !important;
}

.fc-event, .fc-event-dot {
    background-color: #639061;
    border: none;
}

.fc-event {
    position: relative;
    display: block;
    font-size: .85em;
    line-height: 1.3;
    border-radius: 3px;
    margin: 5px;
    border: none;
}

.fc .fc-list-event-dot {
   display: none;
}

.fc-event:hover {
    background-color: #388134;
}

.fc .fc-list-event:hover td{
    background-color: #388134;
}